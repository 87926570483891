<template>
    <LoginForm />
</template>

<script>
import LoginForm from '@/components/auth/LoginForm'

export default({
    components: {
        LoginForm
    },
})
</script>
